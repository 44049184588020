import React, { FC } from 'react';
import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl
        defaultImage: image
        twitterUsername
      }
    }
  }
`;

export interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
  article?: boolean;
}

export const SEO: FC<SEOProps> = ({ title, description, image, article = false }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`
  };

  return <Helmet title={seo.title} titleTemplate={titleTemplate}>
    <meta charSet="utf-8"/>
    <meta name="description" content={seo.description}/>
    <meta name="image" content={seo.image}/>
    {seo.url && <meta property="og:url" content={seo.url}/>}
    {(article ? true : null) && <meta property="og:type" content="article"/>}
    {seo.title && <meta property="og:title" content={seo.title}/>}
    {seo.description && (
      <meta property="og:description" content={seo.description}/>
    )}
    {seo.image && <meta property="og:image" content={seo.image}/>}
    <meta name="twitter:card" content="summary_large_image"/>
    {twitterUsername && (
      <meta name="twitter:creator" content={twitterUsername}/>
    )}
    {seo.title && <meta name="twitter:title" content={seo.title}/>}
    {seo.description && (
      <meta name="twitter:description" content={seo.description}/>
    )}
    {seo.image && <meta name="twitter:image" content={seo.image}/>}
  </Helmet>;
};
